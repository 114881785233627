import React from 'react'
import PostPreview from './PostPreview'
import '../../static/styles/author-posts.css'

const AuthorPosts = ({ name, items }) =>
  items && items.length > 0 ? (
    <section className="author-posts">
      <h2 className="author-posts__heading">More articles from {name}</h2>
      <div className="author-posts__items">
        {items.map((item, id) => (
          <PostPreview key={id} node={item} />
        ))}
      </div>
    </section>
  ) : (
    ''
  )

export default AuthorPosts
