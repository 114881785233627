import React, { useState, useEffect } from "react"
import { useStoryblokState } from "gatsby-source-storyblok"
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import AuthorDetail from '../components/AuthorDetail'
import Seo from "components/Seo"

const AuthorDetailPage = ({
  data: {
    author,
    allSitePage,
    allKnowledgePages,
    posts,
  },
  path,
}) => {
  const knowledgePages = allKnowledgePages.nodes
    .map((node) => {
      const sitePage = allSitePage.nodes.find(
        ({ pageContext: { slug } }) => slug === node.slug
      )

      return (
        sitePage && {
          ...node,
          slug: sitePage && sitePage.path.substr(1),
        }
      )
    })
    .filter((knowledgePage) => knowledgePage !== undefined)

  const allPosts = [...knowledgePages, ...posts.nodes]
    .sort((a, b) =>
      b.publishedAtTimestamp.localeCompare(a.publishedAtTimestamp)
    )
    .slice(0, 4)

  // This will be used to set live editor preview data
  const [previewStory, setPreviewStory] = useState(null);

  // Parse Storyblok data
  let story = useStoryblokState(author);

  // I can't remember why this was needed, so let's first test the Storyblok editor experience with it and then without it
  useEffect(() => {
    if (window.location.search.includes('_storyblok')) {
      setPreviewStory(null);
    }
  }, [story]);

  // If inside Storyblok editor, execute this
  useEffect(() => {
    if (window.location.search.includes('_storyblok')) {

      // Storyblok API endpoint
      const url = `https://api-us.storyblok.com/v2/cdn/stories/${author.full_slug}?version=draft&token=${process.env.GATSBY_STORYBLOK_TOKEN}&cv=${Date.now()}`;

      // Fetch preview data from Storyblok API
      const fetchData = async () => {
        try {
          const response = await fetch(url);
          const json = await response.json();
          setPreviewStory(json.story);
        } catch (error) {
          console.log("error", error);
        }
      };

      fetchData();
      
      // Let's wait until the Storyblok script has loaded
      const waitForStoryblokToLoad = function () {
        if (!window.StoryblokBridge) {
          setTimeout(waitForStoryblokToLoad, 100)
        } else {
            const { StoryblokBridge } = window
            const storyblokInstance = new StoryblokBridge()

            // Update live preview when Storyblok story changes
            storyblokInstance.on('input', (event) => {
                setPreviewStory(event.story);
            })
        }
      }

      waitForStoryblokToLoad();
    }
  }, []);

  // Conditional that checks whether to show original GraphQL data or the preview data
  const content = previewStory ? previewStory.content : story.content;

  return (
    <>
      <Seo
        title={author?.name}
        description={content?.role}
        url={'authors/' + author.slug}
        image={content?.avatar?.imgix_url}
      />
      <AuthorDetail
        name={author.name}
        slug={author.slug}
        role={content?.role}
        youtube={content?.youtube}
        twitter={content?.twitter}
        linkedin={content?.linkedin}
        website={content?.website}
        image={content?.avatar}
        content={content?.content}
        posts={allPosts}
        data={content}
      />
    </>
  )
}

export default AuthorDetailPage

export const AuthorDetailPageQuery = graphql`
  query AuthorDetailPageQuery(
    $slug: String!
    $filterRegex: String!
  ) {
    author: storyblokEntry(slug: { eq: $slug }) {
      content
      name
      slug
      full_slug
    }
    allSitePage(
      filter: {
        componentChunkName: {
          eq: "component---src-templates-knowledge-page-js"
        }
      }
    ) {
      nodes {
        pageContext
        path
      }
    }
    allKnowledgePages: allStoryblokEntry(
      filter: {
        field_component: { eq: "knowledgepage" }
        content: { regex: $filterRegex }
      }
      sort: { order: [DESC], fields: [published_at] }
      limit: 4
    ) {
      nodes {
        name
        slug
        content
        published_at(formatString: "MMMM D, YYYY")
        publishedAtTimestamp: published_at
      }
    }
    posts: allStoryblokEntry(
      filter: {
        field_component: { eq: "post" }
        field_hidden_boolean: { ne: true }
        content: { regex: $filterRegex }
      }
      sort: { order: [DESC], fields: [published_at] }
      limit: 4
    ) {
      nodes {
        slug
        name
        content
        published_at(formatString: "MMMM D, YYYY")
        publishedAtTimestamp: published_at
      }
    }
  }
`
