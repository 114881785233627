import React from 'react'
import Breadcrumb from './Breadcrumb'
import AuthorPosts from './AuthorPosts'
import '../../static/styles/author-detail.css'
import youtubeIcon from '../../static/assets/youtube-icon.svg'
import twitterIcon from '../../static/assets/twitter-icon.svg'
import linkedinIcon from '../../static/assets/linkedin-icon.svg'
import websiteIcon from '../../static/assets/website-icon.svg'
import RichTextRenderer from 'utils/RichTextRenderer'
import Image from 'utils/Image'
import { storyblokEditable } from "gatsby-source-storyblok"

const AuthorDetail = ({
  name,
  slug,
  role,
  youtube,
  twitter,
  linkedin,
  website,
  image,
  content,
  posts,
  data,
}) => {
  const socialLinks = []

  if (youtube) {
    socialLinks.push({
      type: 'YouTube',
      url: youtube,
      icon: youtubeIcon,
    })
  }

  if (twitter) {
    socialLinks.push({
      type: 'Twitter',
      url: twitter,
      icon: twitterIcon,
    })
  }

  if (linkedin) {
    socialLinks.push({
      type: 'LinkedIn',
      url: linkedin,
      icon: linkedinIcon,
    })
  }

  if (website) {
    socialLinks.push({
      type: 'Website',
      url: website,
      icon: websiteIcon,
    })
  }

  return (
    <article className="author-detail" {...storyblokEditable(data)}>
      <section className="author-detail__banner">
        <div className="container">
          <div className="author-detail__breadcrumbs">
            <Breadcrumb
              items={[
                {
                  name: 'Authors',
                  path: 'authors',
                },
                { name: name, path: `authors/${slug}` },
              ]}
            />
          </div>
          <header className="author-detail__header">
            <div className="author-detail__name-role-social">
              <div>
                <h1 className="author-detail__name">{name}</h1>
                {role && <p className="author-detail__role">{role}</p>}
              </div>
              {socialLinks.length > 0 && (
                <ul className="author-detail__social-list">
                  {socialLinks.map(({ type, url, icon }, id) => (
                    <li key={id} className="author-detail__social-item">
                      <a
                        className="author-detail__social-link"
                        href={url}
                        target="_blank"
                        rel="noopener"
                        title={`View ${name}'s ${type}`}
                        aria-label={`View ${name}'s ${type}`}
                      >
                        <img
                          className="author-detail__social-icon"
                          src={icon}
                          alt={type}
                          width="28"
                          height="28"
                          loading="lazy"
                        />
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </div>
            {image?.filename ?
              <Image img={image} eager className="author-detail__image" sizes="(min-width: 780x) 450px, 100vw" />
              :
              <img
                className="author-detail__image"
                src={`https://a-us.storyblok.com/f/1017544/256x256/1f47a25871/officiallogo.png?fit=crop&w=510&h=600`}
                alt=""
                loading="eager"
              />
            }
          </header>
        </div>
      </section>
      {(content || (posts && posts.length > 0)) && (
        <div className="author-detail__main">
          <div className="container">
            {content && (
              <div className="author-detail__content-cta">
                <article {...storyblokEditable(content)} className="post-content author-detail__content">
                  <RichTextRenderer data={content} />
                </article>
              </div>
            )}
            <AuthorPosts name={name} items={posts} />
          </div>
        </div>
      )}
    </article>
  )
}

export default AuthorDetail
